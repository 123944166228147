.App {
  text-align: center;
  background-image: url('../public/HjallurBackground.png');
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-logo {
  width: 100%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem;

  /* Firefox background color */
  background-color: #15364ee0;

  min-height: 60vh;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;

  margin-right: 1rem;
  margin-left: 1rem;
}

@supports (-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px)) {
  .App-header {
    background-color: transparent;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
}

.App-link {
  margin-top: 1rem;
  height: 4rem;
  object-fit: contain;
  color: #61dafb;
}

.App-link img {
  height: 100%;
  object-fit: contain;
}

.main {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1rem;
}

.main div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.main img {
  margin-bottom: 0.5rem;
}

button {
  cursor: pointer;
  font-size: 1.2rem;
  padding: 1rem;
  color: #fff;
  background-color: rgba(22, 40, 73, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 0.2s ease-in-out;
}

button:hover {
  background-color: rgba(22, 40, 73, 0.3);
  transform: scale(1.05);
}

button:active {
  background-color: rgba(22, 40, 73, 0.7);
}
